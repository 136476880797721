<script setup lang="ts">
const { mapVisible = false } = defineProps<{
  mapVisible?: boolean
}>()

defineEmits<{
  'toggle-filters-modal': []
  'toggle-map': []
}>()

const { filtersCount } = useSearchFiltersCount()
</script>

<template>
  <div class="sticky top-0 z-fixed bg-white px-4 py-2">
    <SearchInputV2MobileContainer small-input />
    <div class="mt-3 flex items-center gap-2">
      <button
        class="flex h-[35px] items-center border border-gray-200 px-3 text-md font-medium"
        type="button"
        @click="$emit('toggle-map')"
      >
        <BaseIcon :name="mapVisible ? 'picture' : 'map'" class="mr-2.5" />
        {{ mapVisible ? $t('search.listView') : $t('search.mapView') }}
      </button>
      <button
        class="flex h-[35px] items-center border border-gray-200 px-3 text-md font-medium"
        type="button"
        @click="$emit('toggle-filters-modal')"
      >
        {{ $t('search.filters') }} ({{ filtersCount }})
      </button>
    </div>
  </div>
</template>
