import type { Locale, SupportedCurrencies } from 'lc-services/types'
import type {
  QueryModelType,
  RefetchResultsKeys,
  StateSearchResults,
} from '~/types/search/types'

export const useSearchResults = ({
  startDate,
  endDate,
}: {
  startDate: ComputedRef<string | null>
  endDate: ComputedRef<string | null>
}) => {
  const stateSearchResults = useState<StateSearchResults>(
    'state-search-results',
  )

  const { $lcServicesSearch } = useNuxtApp()
  const { searchController, searchPresenter } = $lcServicesSearch
  const { algoliaIds } = useAuth()
  const { locale } = useI18n()

  const hits = computed(() => stateSearchResults.value.hits)
  const hitsMap = computed(() => stateSearchResults.value.hitsMap)
  const nbHits = computed(() => stateSearchResults.value.nbHits)
  const nbPages = computed(() => stateSearchResults.value.nbPages)
  const page = computed(() => stateSearchResults.value.page)
  const facets = computed(() => stateSearchResults.value.facets)
  const hasResults = computed(() =>
    Boolean(stateSearchResults.value.hits.length),
  )
  const keepOldResults = computed(
    () => stateSearchResults.value.meta.keepOldResults,
  )
  const initClientSide = computed(
    () => stateSearchResults.value.meta.initClientSide,
  )
  const housesPerPage = computed(
    () => stateSearchResults.value.meta.housesPerPage,
  )
  const showPreviousButton = computed(
    () => stateSearchResults.value.meta.showPreviousButton,
  )
  const algoliaQueryId = computed(
    () => stateSearchResults.value.meta.algoliaQueryId,
  )

  const invalidHouses = computed(() => {
    let firstHouseId = null
    let secondHouseId = null

    // Get first house
    if (hits.value.some((h) => h.allMinimumDurationValid === false)) {
      firstHouseId = hits.value.find(
        (h) => h.allMinimumDurationValid === false,
      )?.id
    }
    // Get second house
    if (
      hits.value.filter((h) => h.allMinimumDurationValid === false).length >= 2
    ) {
      secondHouseId = hits.value.filter(
        (h) => h.allMinimumDurationValid === false,
      )[1].id
    }

    return {
      first: firstHouseId,
      second: secondHouseId,
    }
  })

  const hasOnlyInvalidResults = computed(() => {
    // Date + has one invalid house + no valid house
    return (
      startDate.value &&
      endDate.value &&
      hits.value.some((h) => h.allMinimumDurationValid === false) &&
      !hits.value.some((h) => h.allMinimumDurationValid === true)
    )
  })

  const fetchHouseResults = async ({
    currency,
    searchHousesPayload,
  }: {
    currency: Ref<SupportedCurrencies>
    searchHousesPayload: QueryModelType
  }) => {
    await searchController.searchHousesV2(
      searchHousesPayload,
      locale.value as Locale,
      currency.value,
      algoliaIds.value,
      'web',
    )

    if (showPreviousButton.value && page.value === 0) {
      stateSearchResults.value.meta.showPreviousButton = false
    }

    const results = []
    if (keepOldResults.value) {
      const hitsId = stateSearchResults.value.hits.map((h) => h.id)
      const newHits = searchPresenter.vm.searchHousesResult.filter(
        (h) => !hitsId.includes(h.id),
      )
      results.push(...hits.value, ...newHits)
    } else {
      results.push(...searchPresenter.vm.searchHousesResult)
    }

    if (import.meta.client) {
      stateSearchResults.value.meta.initClientSide = true
    }

    stateSearchResults.value = {
      ...stateSearchResults.value,
      facets: searchPresenter.vm.facets,
      hits: results,
      hitsMap: [],
      meta: {
        ...stateSearchResults.value.meta,
        algoliaQueryId: searchPresenter.vm.algoliaQueryId,
      },
      nbHits: searchPresenter.vm.nbHouses,
      nbPages: searchPresenter.vm.nbPages,
      page: searchPresenter.vm.searchCurrentPage,
    }
  }

  const fetchHouseMapResults = async ({
    currency,
    searchHousesPayload,
  }: {
    currency: Ref<SupportedCurrencies>
    searchHousesPayload: QueryModelType
  }) => {
    await searchController.searchHousesMap(
      {
        ...searchHousesPayload,
        locale: locale.value as Locale,
        currency: currency.value,
      },
      algoliaIds.value,
      'web',
    )

    stateSearchResults.value = {
      ...stateSearchResults.value,
      facets: searchPresenter.vm.facets,
      hitsMap: searchPresenter.vm.houseCoordinates?.features || [],
      nbHits: searchPresenter.vm.nbHouses,
      nbPages: searchPresenter.vm.nbPages,
      page: searchPresenter.vm.searchCurrentPage,
    }
  }

  const resetRefetchProperties = (from: RefetchResultsKeys) => {
    const resetInitializesFrom = [
      'budget',
      'clearFilterNumeric',
      'clearQuery',
      'currency',
      'dates',
      'excluFilters',
      'facets',
      'getParentDestiHits',
      'multiple-[location-dates-capacity]',
      'numericFilters-bathrooms',
      'numericFilters-bedrooms',
      'numericFilters-capacity',
      'query',
      'refineByQuery',
      'replica',
    ]

    setKeepOldResults(false)
    if (resetInitializesFrom.includes(from)) {
      if (page.value >= 1) setPage(0)
    } else if (from === 'next-page') {
      setKeepOldResults(true)
    }
  }

  const setPage = (page: number) => {
    stateSearchResults.value.page = page
  }

  const setKeepOldResults = (keepOldResults: boolean) => {
    stateSearchResults.value.meta.keepOldResults = keepOldResults
  }

  return {
    algoliaQueryId,
    facets,
    fetchHouseMapResults,
    fetchHouseResults,
    hasOnlyInvalidResults,
    hasResults,
    hits,
    hitsMap,
    housesPerPage,
    initClientSide,
    invalidHouses,
    keepOldResults,
    nbHits,
    nbPages,
    page,
    resetRefetchProperties,
    setKeepOldResults,
    setPage,
    showPreviousButton,
  }
}
