import type { SupportedCurrencies } from 'lc-services/types'
import { createQueryMultipleDestinationIds } from '~/helpers/search/createQueryMultipleDestinationIds'
import { constructReplica } from '~/helpers/search/constructReplica'
import { createDates } from '~/helpers/search/createDates'
import { createFacetFilters } from '~/helpers/search/createFacetFilters'
import { createNumericFilters } from '~/helpers/search/createNumericFilters'
import { createQueryMultipleHouseIds } from '~/helpers/search/createQueryMultipleHouseIds'
import { findReplica } from '~/helpers/search/findReplica'
import { getBudget } from '~/helpers/search/getBudget'
import { handlePage } from '~/helpers/search/handlePage'

import type { LocationQuery } from 'vue-router'
import type {
  InputSearchQuery,
  StateSearchBudgetFilters,
  StateSearchNumericFilter,
  StateSearchFacetFilter,
  StateSearchDateFilters,
  StateSearchLocationFilters,
  StateSearchReplicaFilters,
  StateSearchResults,
} from '~/types/search/types'

export const useSearchInitStates = ({
  typeSearch,
  destination,
  userCurrency,
  queryOutput,
}: {
  typeSearch: 'public' | 'admin' | 'b2b2c'
  destination: { id: string; name: string }
  userCurrency: SupportedCurrencies
  queryOutput: LocationQuery
}) => {
  const config = useRuntimeConfig()
  const { $httpCookies } = useNuxtApp()
  const { locale } = useI18n()
  const { isMobile } = useDevice()
  const route = useRoute()
  const { setData } = useSearchInput()

  // Get replica
  const replicas = constructReplica({ config, userType: typeSearch })
  const replica = findReplica(replicas, route.query)

  const query = ref<Partial<InputSearchQuery>>()
  // NumericFilters
  const { selectedNumericFilters } = createNumericFilters(route.query)

  // Date
  const { endDate, startDate } = createDates({
    query: route.query,
    $httpCookies,
  })

  // Price
  const { minBudget, maxBudget } = getBudget({
    query: route.query,
    userCurrency,
  })

  // FacetFilters
  const { facetFilters } = createFacetFilters(queryOutput, locale)

  // Page
  const { page, showPreviousButton } = handlePage(route.query, isMobile)

  if (destination?.id) {
    query.value = {
      label: destination.name,
      value: Number(destination.id),
    }

    setData('location', {
      id: destination.id,
      name: destination.name,
      type: 'destination',
    })
  }

  setData('guest', selectedNumericFilters.capacity)
  setData('startDate', startDate.value)
  setData('endDate', endDate.value)

  // Multiple houses ids
  const queryMultipleHousesIds = createQueryMultipleHouseIds(route.query)

  // Multiple destinations ids
  const queryMultipleDestinationsIds = createQueryMultipleDestinationIds(
    route.query,
  )

  //
  // Update useState for numeric filters
  const stateSearchNumericFilter = useState<StateSearchNumericFilter>(
    'state-search-numeric-filter',
  )
  stateSearchNumericFilter.value = {
    selectedNumericFilters: {
      bathrooms: selectedNumericFilters.bathrooms || 0,
      bedrooms: selectedNumericFilters.bedrooms || 0,
      capacity: selectedNumericFilters.capacity || 0,
    },
  }

  //
  // Update useState for facet filters
  const stateSearchFacetFilter = useState<StateSearchFacetFilter>(
    'state-search-facet-filter',
  )
  stateSearchFacetFilter.value = {
    facetFilters,
  }

  //
  // Update useState for date filters
  const stateSearchDateFilters = useState<StateSearchDateFilters>(
    'state-search-date-filter',
  )
  stateSearchDateFilters.value = {
    endDate: startDate.value && endDate.value ? endDate.value : null,
    startDate: startDate.value && endDate.value ? startDate.value : null,
  }

  //
  // Update useState for budget filters
  const stateSearchBudgetFilters = useState<StateSearchBudgetFilters>(
    'state-search-budget-filter',
  )
  stateSearchBudgetFilters.value = {
    maxBudget: maxBudget.value,
    minBudget: minBudget.value,
  }

  //
  // Update useState for location filters
  const stateSearchLocationFilters = useState<StateSearchLocationFilters>(
    'state-search-location-filter',
  )
  stateSearchLocationFilters.value = {
    query: query.value || {},
    queryMultiple: [],
    queryMultipleHousesIds,
    queryMultipleDestinationsIds,
  }

  //
  // Update useState for replica filters
  const stateSearchReplicaFilters = useState<StateSearchReplicaFilters>(
    'state-search-replica-filter',
  )
  stateSearchReplicaFilters.value = {
    replicas: replicas.value,
    currentReplica: replica.value,
  }

  //
  // Update useState for results
  const stateSearchResults = useState<StateSearchResults>(
    'state-search-results',
  )
  stateSearchResults.value.page = page.value
  stateSearchResults.value.meta.showPreviousButton = showPreviousButton.value
}
