<script setup>
import { getImgSrc, getOpenGraph, getTwitter } from '~/helpers/useMetaData'

definePageMeta({ middleware: ['search-destination-validation'] })
defineI18nRoute({
  paths: {
    en: '/[destination]/[name]',
    fr: '/[destination]/[name]',
  },
})

const nuxtApp = useNuxtApp()
const { locale } = useI18n()
const { userIsAdmin, userIsPartner } = useAuth()
const route = useRoute()
const stateHousePreviousPage = useState('house-previous-page')
const stateDestination = useState('destination')

// Page lp inspiration
if (route.meta.destinationPage.isLpInspi) {
  const { data: lpInspiration } = await useFetch(
    `/api/${locale.value}/page-inspiration/${route.params.name}`,
  )

  if (lpInspiration.value) {
    stateDestination.value = {
      collections: lpInspiration.value.collections || [],
      destination: {},
      destinationId: null,
      destinations: lpInspiration.value.popularDestinations || [],
      disableQuery: false,
      indexationPage: true,
      isDestinationPage: true,
      isIndexesFilter: false,
      linksSeo: [],
      lpContent: { ...lpInspiration.value.lpContent, isLpInspiration: true },
      metaCoverPhotoUrl: lpInspiration.value.lpContent.coverPhotoUrl,
      metaDesc: lpInspiration.value.lpContent.metaDescription[locale.value],
      metaTitle: lpInspiration.value.lpContent.metaTitle[locale.value],
      seoTitle: '',
    }
  }
}
// Page lp destination
else if (route.meta.destinationPage.isLpDesti) {
  const { data: lpDestination } = await useFetch(
    `/api/${locale.value}/page-destination/${route.params.name}`,
  )

  if (lpDestination.value) {
    stateDestination.value = {
      collections: lpDestination.value.collections || [],
      destination: lpDestination.value.destination || {},
      destinationId: lpDestination.value.lpContent.destinationId,
      destinations: lpDestination.value.popularDestinations || [],
      disableQuery: false,
      indexationPage: true,
      isDestinationPage: true,
      isIndexesFilter: false,
      linksSeo: lpDestination.value.facetsFilters,
      lpContent: { ...lpDestination.value.lpContent, isLpDestination: true },
      metaCoverPhotoUrl: lpDestination.value.lpContent.coverPhotoUrl,
      metaDesc: lpDestination.value.lpContent.metaDescription[locale.value],
      metaTitle: lpDestination.value.lpContent.metaTitle[locale.value],
      seoTitle: '',
    }
  }
}
// Search (search desti / search SEO)
else {
  await useAsyncData('searchDestiOrSearchFacetsSeo', async () => {
    await useSearch({
      loadDesti: route.meta.destinationPage.isDesti,
      loadFacetSeo: route.meta.destinationPage.isSeoFacet,
      locale,
    })
    return true
  })
}

const {
  indexationPage,
  isDestinationPage,
  metaCoverPhotoUrl,
  metaDesc,
  metaTitle,
} = stateDestination.value

onBeforeRouteLeave((_, from) => {
  if (from) {
    const previousPage = {
      name: from.name.slice(0, -5),
      params: from.params,
      query: from.query,
    }

    stateHousePreviousPage.value = previousPage
  }
})

nuxtApp.runWithContext(() => {
  const isSeoFacet = route?.meta?.destinationPage?.isSeoFacet
  const shouldNoIndex = isSeoFacet && !indexationPage
  let robots = {}
  if (shouldNoIndex) {
    robots = {
      hid: 'robots',
      name: 'robots',
      content: 'noindex',
    }
  }

  useHead({
    title: metaTitle,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: metaDesc,
      },
      ...getOpenGraph(metaTitle, metaDesc, metaCoverPhotoUrl),
      ...getTwitter(metaTitle, metaDesc, metaCoverPhotoUrl),
      robots,
    ],
    link: [...getImgSrc(metaCoverPhotoUrl)],
  })
})
</script>

<template>
  <div>
    <Destination v-if="isDestinationPage" />
    <SearchAdminLayout v-else-if="userIsAdmin" />
    <SearchPartnerLayout v-else-if="userIsPartner" />
    <SearchClientLayout v-else />
  </div>
</template>
