<script setup lang="ts">
import { $dayjs } from '@/utils/dayjs'
import { reviewsList } from '~/assets/js/reviews'
import { MIN_DEFAULT_BUDGET, MAX_DEFAULT_BUDGET } from '@/helpers/priceHelper'

import type { Locale } from 'lc-services/types'
import type {
  InputSearchQuery,
  NumericFilter,
  QueryModelType,
  RefetchResultsKeys,
  ReplicasValues,
  SearchClientHouse,
  SearchFacetObject,
  SearchFacetValue,
} from '~/types/search/types'
import type { StateDestination } from '~/types/types'

const { isSmallScreen, isLargeScreen, isDesktopOrTablet } = useBreakpoint()
const { $lcRepositories } = useNuxtApp()
const { t, locale } = useI18n<unknown, Locale>()
const { isNewHeader } = useHeaderV2()

$dayjs.locale(locale.value)

const stateDestination = useState<StateDestination>('destination')

const seoTitle = stateDestination.value?.seoTitle || ''
const linksSeo = stateDestination.value?.linksSeo || []
const destination = stateDestination.value?.destination || {}
const disableQuery = stateDestination.value?.disableQuery || false
const isIndexesFilter = stateDestination.value?.isIndexesFilter || false
let destinationId = stateDestination.value?.destinationId || null
const queryOutput = stateDestination.value?.queryOutput || {}

const localePath = useLocalePath()
const route = useRoute()
const router = useRouter()
const { headerNavigation } = useHeaderNavigation()
const { setFiltersCount } = useSearchFiltersCount()
const { currency } = useUser()
const { trackAlgoliaClick } = useAlgoliaSearchInsights()
const { data, submittedSearch, setSubmittedSearch } = useSearchInput()

const isLoading = ref(false)
const mapVisible = ref(route.query.map === 'open')
const searchContainer = ref<HTMLElement | null>(null)
const showFiltersModal = ref(false)

const {
  bathrooms,
  bedrooms,
  capacity,
  setNumericFilter,
  clearAllNumericFilters,
  clearNumericFilterByType,
} = useSearchNumericFilters()

const {
  newFacetFilters,
  facetsFiltersParams,
  facetFilters,
  clearAllFacetFilters,
  setFacetFilter,
} = useSearchFacetFilters()

const {
  clearDateFilters,
  setDateStart,
  setDateEnd,
  startDate,
  endDate,
  hasDates,
} = useSearchDateFilters()

const { minBudget, maxBudget, price, clearBudgetFilters, setBudgetFilters } =
  useSearchBudgetFilters()

const { currentReplica, replicas, setReplicaFilters } =
  useSearchReplicaFilters()

const {
  queryValue,
  apiParamsDestinationIds,
  fetchParentLocation,
  clearLocationFilters,
  setLocation,
} = useSearchLocationFilters()

const {
  algoliaQueryId,
  facets,
  fetchHouseMapResults,
  fetchHouseResults,
  hasOnlyInvalidResults,
  hasResults,
  hitsMap,
  housesPerPage,
  initClientSide,
  invalidHouses,
  keepOldResults,
  nbHits,
  nbPages,
  page,
  resetRefetchProperties,
  setKeepOldResults,
  setPage,
  showPreviousButton,
} = useSearchResults({ startDate, endDate })

const { formattedHits, hitsHaveTags } = useSearchClientResults({ hasDates })

const { currentHouseFavoriteSelected, setCurrentHouseFavorite } =
  useSearchWishlist()
const { showMapButton } = useSearchMap(searchContainer, mapVisible)
const {
  trackFavoritePeriod,
  trackResetFilter,
  trackSearchHouse,
  trackSearchMap,
  trackSearchResultsViewed,
  trackSidebar,
} = useSearchTracking()
const { updateRouter } = useSearchUpdateRouter()
const { openScheduler } = useMeetingScheduler()
const {
  favoritePeriod,
  isCorrectHousePeriod,
  isFavoritePeriodWeekly,
  nearPeriods,
  setDatesToFavoritePeriod,
} = useSearchFavoritePeriod()

const searchHousesPayload = computed(() => {
  const payload: QueryModelType = {
    initializelist: !keepOldResults.value || !initClientSide.value,
    mode: 'client',
    bathrooms: bathrooms.value ? bathrooms.value.toString() : '1',
    bedrooms: bedrooms.value ? bedrooms.value.toString() : '1',
    capacity: capacity.value ? capacity.value.toString() : '1',
    itemsPerPage: housesPerPage.value,
    replica: currentReplica.value.label,
  }

  if (apiParamsDestinationIds.value.length) {
    payload.destinationIds = apiParamsDestinationIds.value
  }
  if (page.value > 0) {
    payload.page = page.value
  }
  if (newFacetFilters.value?.size) {
    payload.facetFilters = newFacetFilters.value
  }
  if (price.value.length) {
    payload.prices = price.value
  }
  if (startDate.value && endDate.value) {
    payload.period = {
      startDate: startDate.value,
      endDate: endDate.value,
    }
  }

  return payload
})

const searchDestination = computed(
  () => headerNavigation.value.searchDestination,
)
const showPaginate = computed(() => nbPages.value > page.value + 1)
const numberPlaceholderProducts = computed(() =>
  isDesktopOrTablet.value ? 6 : 2,
)

const searchResultsText = computed(() => {
  if (
    mapVisible.value &&
    hitsMap.value.length > 0 &&
    startDate.value &&
    endDate.value
  ) {
    return t('search.results', hitsMap.value.length)
  }

  return t('search.results', nbHits.value)
})
const staticReviews = computed(() => reviewsList[locale.value])

const { data: searchableTagsIdentifiers } = await useAsyncData(
  'search-client-async-data',
  async () => {
    useSearchInitStates({
      typeSearch: 'public',
      destination,
      userCurrency: currency.value,
      queryOutput,
    })

    if (import.meta.client) {
      setKeepOldResults(false)
      setPage(0)
    }

    await fetchHouseResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    })

    // No result
    if (formattedHits.value.length === 0 && destinationId) {
      await fetchParentLocation(Number(destinationId))
      await fetchHouseResults({
        currency,
        searchHousesPayload: searchHousesPayload.value,
      })
    }

    if (mapVisible.value) {
      await fetchHouseMapResults({
        currency,
        searchHousesPayload: searchHousesPayload.value,
      })
    }

    const res = await $lcRepositories.searchableTag.getIdentifiers()

    return (res?.errors ? [] : res) as typeof res.data
  },
)

// Watch
watch(currency, async (_) => {
  await refetchResultsFrom('currency')
})

watch(showFiltersModal, (newValue) => {
  if (newValue) {
    window.scrollTo(0, 0)
  }
})

// Watch when header search click on desti
watch(
  searchDestination,
  async (
    newValue: { name: { en: string; fr: string }; destinationId: number },
    _,
  ) => {
    if (newValue) {
      const fakeSelectedOption: InputSearchQuery = {
        label: newValue.name[locale.value],
        slug: '',
        type: 'location',
        value: newValue.destinationId,
      }

      await refineByQuery(fakeSelectedOption)
    }
  },
)

watch(
  () => route.query,
  () => {
    if (route.query?.map) toggleMap(false)
  },
)

const clearRefinements = () => {
  if (isNewHeader.value) clearFiltersSearchV2()
  else clearFilters()
}

const clearAllModalMobile = () => {
  if (isNewHeader.value) clearFiltersSearchV2()
  else clearAllFilterModal()
}

// Methods
const clearAllFilterModal = async () => {
  clearAllFacetFilters()
  clearAllNumericFilters()

  await refetchResultsFrom('clearAll')
}

const clearFilters = async () => {
  clearBudgetFilters()
  clearAllFacetFilters()
  clearAllNumericFilters()
  clearDateFilters()

  await refetchResultsFrom('clearAll')
}

const clearFiltersSearchV2 = async () => {
  clearBudgetFilters()
  clearAllFacetFilters()
  clearNumericFilterByType('bathrooms')
  clearNumericFilterByType('bedrooms')

  await refetchResultsFrom('clearAll')

  trackResetFilter()
}

const clearQuery = async () => {
  destinationId = ''
  clearLocationFilters()

  await refetchResultsFrom('clearQuery')
}

const clearFilterNumeric = async (type: NumericFilter) => {
  clearNumericFilterByType(type)

  await refetchResultsFrom('clearFilterNumeric')
}

const toggleMap = async (sendTracking = true) => {
  window.scrollTo(0, 0)

  if (!mapVisible.value) {
    await refetchResultsFrom('map')

    if (sendTracking) trackSearchMap({ event: 'map_open' })
    mapVisible.value = true
  } else {
    if (sendTracking) trackSearchMap({ event: 'map_close' })
    mapVisible.value = false
    await refetchResultsFrom('multiple-[location-dates-capacity]')
  }
}

const filteredNumeric = async (
  attribute: NumericFilter,
  selectedFilter: number,
) => {
  setNumericFilter(attribute, selectedFilter)
  await refetchResultsFrom(`numericFilters-${attribute}`)
}

const paginate = async (
  pageDirection: 'next-page' | 'previous-page' = 'next-page',
) => {
  // we need to set previousPage and nextPage so that if multiple pages are already visible we don't duplicate content
  if (pageDirection === 'previous-page') {
    setPage(page.value - 1)
  } else {
    setPage(page.value + 1)
  }

  await refetchResultsFrom(pageDirection)

  window.scrollTo(0, window.scrollY)
}

const refineByQuery = async (selectedOption: InputSearchQuery) => {
  setLocation(selectedOption.label, selectedOption.value)
  const house = selectedOption.type === 'type-of-house' ? selectedOption : null
  const destination = selectedOption.type === 'location' ? selectedOption : null

  if (house) {
    const path = localePath({
      name: 'house-slug',
      params: { slug: house.slug },
    })

    router.push({ path })
  } else if (destination) {
    destinationId = String(destination.value)

    await refetchResultsFrom('query')
  }
}

const refineFacets = async (
  facet: SearchFacetObject<SearchFacetValue<string>[], true>,
  facetValue: { checked: boolean; value: string },
) => {
  facetValue.checked = !facetValue.checked

  setFacetFilter(facet, facetValue)

  await refetchResultsFrom('facets')
}

const datesCleared = async () => {
  clearDateFilters()

  await refetchResultsFrom('dates')
}

const handleSetDatesStart = (date: Date) => {
  setDateStart(date)
}

const handleSetDatesEnd = (date: Date) => {
  setDateEnd(date)
  setDates()
}

const handleSetFavoritePeriod = (period: 'previous' | 'next') => {
  const id = queryValue.value?.value?.toString()
  const name = queryValue.value?.label
  if (id && name)
    trackFavoritePeriod({
      id,
      name,
    })
  setDatesToFavoritePeriod(period)
  setDates()
}

const setDates = async () => {
  if (startDate.value && endDate.value) {
    await refetchResultsFrom('dates')
  }
}

const sortByReplica = async (replica: {
  label: ReplicasValues
  value: string
}) => {
  setReplicaFilters(replica)

  await refetchResultsFrom('replica')
}

const updateBudget = async (maxB: string, minB: string) => {
  setBudgetFilters(minB, maxB)

  await refetchResultsFrom('budget')
}

const setFavoriteHouse = (house: SearchClientHouse) => {
  if (house) {
    const price = house.currentPrice?.isValid
      ? house.currentPrice.value
      : undefined

    setCurrentHouseFavorite({
      id: house.id,
      name: house.name,
      iconicCollection: house.iconicCollection,
      publicPrice: price || undefined,
    })
  }
}

const triggerClick = () => {
  searchContainer.value?.click()
}

const handleClick = (houseId: number, position: number) => {
  trackHouse(houseId)

  trackAlgoliaClick({
    indexName: currentReplica.value.value,
    objectIDs: [houseId.toString()],
    positions: position ? [position] : undefined,
    queryID: algoliaQueryId.value,
  })
}

const trackHouse = (houseId: number) => {
  trackSearchHouse({
    houseId,
    mapVisible: mapVisible.value,
    searchableTagsIdentifiers: searchableTagsIdentifiers.value || [],
  })
}

const showHouseCardInfo = (houseId: number) => {
  if (isFavoritePeriodWeekly.value) return false
  const twoCardsPerLine =
    !isLargeScreen.value && invalidHouses.value.first === houseId
  const threeCardsPerLine =
    isLargeScreen.value && invalidHouses.value.second
      ? Number(invalidHouses.value.second) === Number(houseId)
      : Number(invalidHouses.value.first) === Number(houseId)

  return threeCardsPerLine || twoCardsPerLine
}

const toggleFiltersModal = () => {
  showFiltersModal.value = !showFiltersModal.value
}

onBeforeMount(() => {
  if (!showPreviousButton.value && page.value > 1) {
    const query = route.query

    delete query.page
    router.push({ query: { ...query } })
  }
})

const refetchResultsFrom = async (from: RefetchResultsKeys) => {
  resetRefetchProperties(from)

  isLoading.value = true

  if (from === 'map' || mapVisible.value) {
    await fetchHouseMapResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    })
  } else {
    await fetchHouseResults({
      currency,
      searchHousesPayload: searchHousesPayload.value,
    })
  }

  // No result
  if (formattedHits.value.length === 0 && destinationId) {
    await fetchParentLocation(Number(destinationId))
    destinationId = null
    await refetchResultsFrom('getParentDestiHits')

    return
  }

  isLoading.value = false

  trackSearchResultsViewed({
    isCorrectHousePeriod: hasDates.value
      ? isCorrectHousePeriod.value
      : undefined,
    mapVisible: mapVisible.value,
    searchableTagsIdentifiers: searchableTagsIdentifiers.value || [],
  })

  updateRouter(isIndexesFilter, disableQuery)
}

if (
  route?.name?.toString()?.includes('destination-slug-filter') &&
  nbHits.value === 0
) {
  const path = localePath({
    name: 'destination-name',
    params: { name: route.params.slug },
  })

  navigateTo(path, { redirectCode: 301 })
}

const searchLinksTitle = computed(() => {
  const destinationType = destination.searchUrl?.[locale.value]
    ?.split('/')?.[0]
    ?.split('-')?.[1]

  return t('search.typesOfVillas', {
    destination: destination.name,
    houseType: destinationType,
  })
})

onMounted(() => {
  setFiltersCount(countTotalFiltersSelected.value)
  trackSearchResultsViewed({
    isCorrectHousePeriod: hasDates.value
      ? isCorrectHousePeriod.value
      : undefined,
    mapVisible: mapVisible.value,
    searchableTagsIdentifiers: searchableTagsIdentifiers.value || [],
  })
})

// Count selected filters
const countTotalFiltersSelected = computed(() => {
  let total = 0

  if (bathrooms.value) total += 1
  if (bedrooms.value) total += 1

  if (isNewHeader.value) {
    if (minBudget.value !== MIN_DEFAULT_BUDGET) total += 1
    if (maxBudget.value !== MAX_DEFAULT_BUDGET) total += 1
  } else {
    if (capacity.value) total += 1
  }

  total += facetsFiltersParams.value.length || 0

  return total
})

watch(
  () => countTotalFiltersSelected.value,
  (val) => {
    setFiltersCount(val)
  },
)

const showSidebar = ref(true)
const toogleSidebar = () => {
  showSidebar.value = !showSidebar.value
  trackSidebar({
    searchableTagsIdentifiers: searchableTagsIdentifiers.value || [],
    show: showSidebar.value,
  })
}

const updateData = () => {
  setNumericFilter('capacity', data.value.guest)

  if (data.value.location.name && data.value.location.id) {
    setLocation(data.value.location.name, Number(data.value.location.id))
  } else clearLocationFilters()

  if (data.value.startDate && data.value.endDate) {
    setDateStart(data.value.startDate)
    setDateEnd(data.value.endDate)
  } else clearDateFilters()
}

watch(
  [data, isSmallScreen, submittedSearch],
  async (values) => {
    if ((data.value.startDate && !data.value.endDate) || import.meta.server)
      return

    const [_, isSmallScreenValue, submittedSearchValue] = values

    if (isSmallScreenValue || (!isSmallScreenValue && submittedSearchValue)) {
      updateData()
      await refetchResultsFrom('multiple-[location-dates-capacity]')
    }

    if (submittedSearchValue) {
      setSubmittedSearch(false)
    }
  },
  { deep: true },
)
</script>

<template>
  <div class="mb-4 w-full">
    <div ref="searchContainer">
      <div
        v-if="isLoading"
        class="fixed inset-x-0 top-0 z-fixed bg-gray-200/90"
      />

      <SearchTopBarClient
        v-if="!isNewHeader"
        :end-date="endDate"
        :capacity="capacity"
        :bedrooms="bedrooms"
        :bathrooms="bathrooms"
        :favorite-period="favoritePeriod"
        :is-favorite-period-weekly="isFavoritePeriodWeekly"
        :is-loading="isLoading"
        :max-budget="maxBudget"
        :min-budget="minBudget"
        :query-value="queryValue"
        :start-date="startDate"
        @clear-filter-numeric="clearFilterNumeric"
        @clear-query="clearQuery"
        @dates-cleared="datesCleared"
        @refine-by-query="refineByQuery"
        @refine-filter-numeric="filteredNumeric"
        @toggle-filters-modal="showFiltersModal = true"
        @trigger-click="triggerClick"
        @update:end-date="handleSetDatesEnd"
        @update:start-date="handleSetDatesStart"
        @update-budget="updateBudget"
      />
      <SearchTopBarV2Mobile
        v-else-if="isNewHeader && isSmallScreen"
        :map-visible="mapVisible"
        @toggle-filters-modal="showFiltersModal = true"
        @toggle-map="toggleMap"
      />

      <SearchNoResult v-if="!hasResults" @clear-refinements="clearRefinements">
        <SearchWaitingListCard
          v-if="destinationId"
          :banner="!$device.isMobile"
          :bathrooms="bathrooms"
          :bedrooms="bedrooms"
          :capacity="capacity"
          :currency="currency"
          :destination-id="Number(destinationId)"
          :end-date="endDate"
          :max-budget="maxBudget"
          :min-budget="minBudget"
          :start-date="startDate"
          class="mt-12"
        />
      </SearchNoResult>

      <SearchResultHeader
        v-if="isNewHeader"
        class="px-4 py-3 lg:sticky lg:top-[85px] lg:z-sticky lg:bg-white lg:px-6"
        :current-replica="currentReplica.value"
        :is-loading="isLoading"
        :map-visible="mapVisible"
        :replicas="replicas"
        :search-results-text="searchResultsText"
        show-btn-map-and-sort
        :collapsed-sidebar="!showSidebar"
        @change-replica="sortByReplica"
        @hide-sidebar="toogleSidebar"
        @show-sidebar="toogleSidebar"
        @toggle-map="toggleMap"
      />

      <div
        :class="[
          'sm:flex sm:px-4 lg:px-6',
          { 'mb-4 transition-transform duration-300 ease-in-out': isNewHeader },
          { 'w-[calc(100%+266px+1.5rem)]': isNewHeader && !showSidebar },
          { 'mt-4': !isNewHeader },
        ]"
      >
        <div
          v-if="!isSmallScreen && hasResults"
          class="sticky h-full"
          :class="{
            'top-[calc(142px+1rem)] w-3/12 xl:top-[calc(95px+1rem)]':
              !isNewHeader,
            'top-[calc(135px+1rem)] mr-5 w-[266px] flex-none transition-transform duration-300 ease-in-out':
              isNewHeader,
            'translate-x-[calc(-266px-1.5rem)]': isNewHeader && !showSidebar,
            'translate-x-0': isNewHeader && showSidebar,
          }"
          data-cy="search-sidebar-filter"
        >
          <SearchFilterSidebar
            :class="{ 'mr-4': !isNewHeader, 'pr-4': isNewHeader }"
            :bathrooms="bathrooms"
            :bedrooms="bedrooms"
            :facet-filters="facetFilters"
            :facets="facets"
            :loading-facets="isLoading"
            :max-budget="maxBudget"
            :min-budget="minBudget"
            @changeFacet="refineFacets"
            @clear-filter-numeric="clearFilterNumeric"
            @refine-filter-numeric="filteredNumeric"
            @update-budget="updateBudget"
            @reset-filters="clearFiltersSearchV2"
          />
        </div>

        <div
          :class="{
            'w-9/12':
              !isNewHeader && !isSmallScreen && hasResults && showSidebar,
            'w-full': !hasResults,
            'flex-1 transition-all duration-300 ease-in-out laptop:w-[calc(100%-266px-1.5rem)]':
              isNewHeader,
            'laptop:translate-x-[calc(-266px-1.5rem)]':
              isNewHeader && !showSidebar,
            'translate-x-0': isNewHeader && showSidebar,
          }"
        >
          <div>
            <BaseRow
              v-if="hasResults"
              id="search-results"
              class="mx-0 md:-mx-2"
            >
              <BaseCol cols="24">
                <template v-if="!isNewHeader || (isNewHeader && !mapVisible)">
                  <SearchResultHeader
                    v-if="!isNewHeader"
                    class="mb-4 px-2 sm:px-0"
                    :current-replica="currentReplica.value"
                    :is-loading="isLoading"
                    :map-visible="mapVisible"
                    :replicas="replicas"
                    :search-results-text="searchResultsText"
                    show-btn-map-and-sort
                    :collapsed-sidebar="!showSidebar"
                    @change-replica="sortByReplica"
                    @hide-sidebar="toogleSidebar"
                    @show-sidebar="toogleSidebar"
                    @toggle-map="toggleMap"
                  />
                  <SearchHotDeals
                    v-if="hitsHaveTags({ 5: 'search.hotdeals.filterLabel' })"
                    class="px-4"
                    @click="
                      (tag, value) =>
                        refineFacets(
                          { key: 5, tag, values: [] },
                          { checked: false, value },
                        )
                    "
                  />
                </template>

                <template v-if="!mapVisible">
                  <SearchListLoadPreviousButton
                    v-if="showPreviousButton"
                    :page="page"
                    @previous-page="paginate('previous-page')"
                  />

                  <BaseRow v-if="isLoading" class="mb-6">
                    <BaseCol
                      v-for="n in numberPlaceholderProducts"
                      :key="`base-house-card-placeholder-${n}`"
                      class="mb-4"
                      cols="24"
                      md="12"
                      :xl="isNewHeader && !showSidebar ? '6' : '8'"
                    >
                      <BaseHouseCardPlaceholder />
                    </BaseCol>
                  </BaseRow>

                  <BaseRow class="mb-2">
                    <template
                      v-for="(house, index) in formattedHits"
                      :key="`house-${house.id}`"
                    >
                      <template
                        v-if="
                          !isLoading &&
                          startDate &&
                          endDate &&
                          invalidHouses.first === house.id
                        "
                      >
                        <BaseCol
                          v-if="isFavoritePeriodWeekly"
                          class="px-0 sm:mb-4 sm:px-2"
                          cols="24"
                          md="12"
                          :xl="isNewHeader && !showSidebar ? '6' : '8'"
                        >
                          <SearchFavoritePeriod
                            class="flex h-full flex-col justify-between rounded bg-beige px-4 py-6 sm:p-6"
                            :favorite-period="favoritePeriod"
                            :near-periods="nearPeriods"
                            @click-favorite-period="handleSetFavoritePeriod"
                            @schedule-call="openScheduler"
                          />
                        </BaseCol>

                        <h2
                          :class="[
                            'mb-8 w-24/24 px-2 text-4xl',
                            hasOnlyInvalidResults
                              ? 'mt-4 sm:mt-8'
                              : 'mt-8 sm:mt-16',
                          ]"
                        >
                          {{
                            $t(
                              'search.invalidPeriodHouses.otherAvailableHouses',
                            )
                          }}
                        </h2>
                      </template>

                      <BaseCol
                        class="mb-6 px-0 sm:px-2 md:mb-4"
                        cols="24"
                        md="12"
                        :xl="isNewHeader && !showSidebar ? '6' : '8'"
                      >
                        <SearchHouseCard
                          :id="house.id"
                          :bathrooms="house.bathrooms"
                          :bedrooms="house.bedrooms"
                          :capacity="house.capacity"
                          :destination="house.destination"
                          :photos="house.photos"
                          :price="house.currentPrice.text"
                          :slug="house.slug"
                          :title="house.name"
                          @handle-click="handleClick(house.id, index + 1)"
                        >
                          <template #card-action>
                            <WishlistToggle
                              v-slot="slotProps"
                              :house-id="house.id"
                              :house-name="house.name"
                              @callback-click="setFavoriteHouse(house)"
                            >
                              <HouseWishlistButton
                                :is-active="slotProps.active"
                              />
                            </WishlistToggle>
                          </template>

                          <template #before-price>
                            <div class="mt-3 flex min-h-7 gap-2">
                              <span
                                v-for="keyfeature in house.highlightKeyFeatures"
                                :key="keyfeature.identifier"
                                class="max-w-[50%] truncate rounded bg-gray-100 px-2 py-1 text-xs"
                              >
                                {{ keyfeature.name }}
                              </span>
                            </div>
                          </template>

                          <template #card-content>
                            <div class="absolute left-4 top-2">
                              <div class="flex text-center">
                                <div
                                  v-if="house.iconicCollection"
                                  data-testid="house-card__iconic"
                                  class="z-20 mr-2 rounded bg-successBg px-2 py-1 text-xs"
                                >
                                  {{ $t('global.iconic') }}
                                </div>
                                <div
                                  v-if="house.hotDeal"
                                  data-testid="house-card__hotdeal"
                                  class="z-20 rounded bg-primary-100 px-2 py-1 text-xs"
                                >
                                  {{ $t('search.hotdeals.bubble') }}
                                </div>
                              </div>
                            </div>
                          </template>
                        </SearchHouseCard>
                      </BaseCol>

                      <BaseCol
                        v-if="showHouseCardInfo(house.id)"
                        class="mb-6 px-0 sm:mb-4 sm:px-2"
                        cols="24"
                        md="12"
                        :xl="isNewHeader && !showSidebar ? '6' : '8'"
                      >
                        <BaseHouseCardInfo />
                      </BaseCol>
                      <BaseCol
                        v-else-if="!isFavoritePeriodWeekly && index === 4"
                        class="mb-6 px-0 sm:mb-4 sm:px-2"
                        cols="24"
                        md="12"
                        :xl="isNewHeader && !showSidebar ? '6' : '8'"
                      >
                        <SearchScheduleACall
                          class="size-full rounded bg-primary-100 px-5 py-4"
                          @schedule-call="openScheduler"
                        />
                      </BaseCol>
                    </template>
                  </BaseRow>

                  <SearchListLoadMoreButton
                    :loading="isLoading"
                    :page="page"
                    :show-paginate="showPaginate"
                    @next-page="paginate('next-page')"
                  />
                </template>

                <LazySearchMap
                  v-else-if="!isLoading"
                  :end-date="endDate"
                  :hits-maps="hitsMap"
                  :start-date="startDate"
                  :track-house="trackHouse"
                  :user-is-admin="false"
                  :user-is-partner="false"
                  class="search__map"
                  @click-toggle-wishlist="setCurrentHouseFavorite"
                />
              </BaseCol>
            </BaseRow>

            <SearchMapButton
              v-if="isSmallScreen"
              :map-visible="mapVisible"
              :show-map-button="showMapButton"
              @toggle-map="toggleMap"
            />
          </div>

          <div>
            <div
              v-if="destinationId && hasResults"
              :class="{ 'mt-6': showPaginate }"
            >
              <SearchWaitingListCard
                class="mt-6"
                :bathrooms="bathrooms"
                :bedrooms="bedrooms"
                :capacity="capacity"
                :currency="currency"
                :destination-id="Number(destinationId)"
                :end-date="endDate"
                :max-budget="maxBudget"
                :min-budget="minBudget"
                :start-date="startDate"
                :total-results="nbHits"
              />
            </div>

            <HouseReviews
              class="mx-0 mt-6"
              full-width
              :reviews="staticReviews"
            />

            <BaseRow v-if="seoTitle" class="mx-0 my-12">
              <BaseCol class="px-0">
                <h1 class="text-center text-5xl">
                  {{ seoTitle }}
                </h1>

                <SearchLinks
                  v-if="linksSeo?.length"
                  :links-seo="linksSeo"
                  :title="searchLinksTitle"
                  class="mt-10"
                />
              </BaseCol>
            </BaseRow>
          </div>
        </div>
      </div>
    </div>

    <SearchFilterModalMobile
      v-model="showFiltersModal"
      :bathrooms-quantity="bathrooms"
      :bedrooms-quantity="bedrooms"
      :capacity-quantity="capacity"
      :facet-filters="facetFilters"
      :max-budget="maxBudget"
      :min-budget="minBudget"
      :facets="facets"
      :loading-facets="isLoading"
      :nb-hits="nbHits"
      @change-facet="refineFacets"
      @clear-all="clearAllModalMobile"
      @clear-filter-numeric="clearFilterNumeric"
      @refine-filter-numeric="filteredNumeric"
      @toggle-filters="toggleFiltersModal"
      @update-budget="updateBudget"
    />

    <WishlistMultipleModal v-bind="currentHouseFavoriteSelected" />
  </div>
</template>

<style>
@screen md {
  .search-filter .form-select .multiselect__select:before {
    @apply hidden;
  }
}

.search-filter .form-select--mobile {
  @apply block mb-4 w-full;
}

@screen md {
  .search-filter .form-select--mobile {
    @apply hidden;
  }
}
.search-filter .form-select--mobile .dropdown-toggle {
  @apply w-full py-4 px-3;
  top: -1rem;
}
.search-filter .form-select--mobile .dropdown-menu {
  @apply mt-2;
}
.search-filter .form-select--mobile .search-filter__icon {
  @apply hidden;
}

.search__map.base-map {
  height: 70vh;
}

@screen lg {
  .search__map.base-map {
    height: 850px;
  }
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-300;
}
.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}
</style>
