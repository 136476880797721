<script setup lang="ts">
import type { ReplicasValues } from '~/types/search/types'

const { t } = useI18n()
const { isSmallScreen } = useBreakpoint()
const { isNewHeader } = useHeaderV2()

const emits = defineEmits<{
  'change-replica': [{ label: ReplicasValues; value: string }]
  'show-sidebar': []
  'hide-sidebar': []
  'toggle-map': []
}>()

const props = withDefaults(
  defineProps<{
    isLoading?: boolean
    mapVisible: boolean
    searchResultsText: string
    currentReplica: string
    replicas: {
      label: ReplicasValues
      value: string
    }[]
    showBtnMapAndSort: boolean
    collapsedSidebar?: boolean
  }>(),
  {
    isLoading: false,
    collapsedSidebar: false,
  },
)

const options = computed(() => {
  return props.replicas.map((replica) => {
    return {
      label: t(`search.${replica.label}`),
      value: replica.value,
    }
  })
})

const defaultReplica =
  options.value.find((o) => o.value === props.currentReplica)?.value || null

const selectedReplica = ref(defaultReplica)

const changeReplica = (replica: string) => {
  const currentReplica = props.replicas.find((r) => r.value === replica)
  if (currentReplica) emits('change-replica', currentReplica)
}
const toggleMap = () => {
  emits('toggle-map')
}
</script>

<template>
  <div class="flex items-center">
    <div
      v-if="isNewHeader && !collapsedSidebar && !isSmallScreen"
      class="w-[286px] flex-none"
    >
      <button
        type="button"
        class="flex items-center text-xs font-semibold"
        @click="$emit('hide-sidebar')"
      >
        <BaseIcon name="arrowLeftCircle" :size="1" class="mr-2" />
        {{ $t('search.hideFilters') }}
      </button>
    </div>
    <div class="flex w-full items-center justify-between">
      <div class="flex items-center">
        <button
          v-if="isNewHeader && collapsedSidebar"
          type="button"
          class="mr-5 flex items-center text-xs font-semibold"
          @click="$emit('show-sidebar')"
        >
          <BaseIcon name="arrowRightCircle" :size="1" class="mr-2" />
          {{ $t('search.showFilters') }}
        </button>

        <div v-if="isLoading" class="animate-wave h-4 w-[70px]" />
        <h2
          v-else
          :class="[
            'm-0 font-sansSerif text-xs',
            {
              'font-normal text-gray-700 md:text-md md:font-medium':
                isNewHeader,
              'font-medium text-gray-600': !isNewHeader,
            },
          ]"
        >
          {{ searchResultsText }}
        </h2>
      </div>

      <div v-if="showBtnMapAndSort" class="flex items-center">
        <template v-if="!mapVisible">
          <LazyBaseFormComponentsBaseMultiselect
            v-model="selectedReplica"
            :can-clear="false"
            :can-deselect="false"
            :height="24"
            :options="options"
            input-label="label"
            name="sortByClient"
            wrapper-class="multiselect-replica m-0 w-[210px]"
            @select="changeReplica"
          >
            <template #multiselect-singlelabel="{ value }">
              <div class="multiselect-single-label">
                <span class="text-md">
                  {{ $t('search.sort_by') }} {{ value.label }}
                </span>
              </div>
            </template>

            <template #multiselect-option="{ option }">
              <span class="text-md">
                {{ option.label }}
              </span>
            </template>
          </LazyBaseFormComponentsBaseMultiselect>
        </template>

        <BaseButton
          v-if="isNewHeader && !isSmallScreen"
          :color="mapVisible ? 'grey' : 'secondary'"
          :variant="mapVisible ? 'outline' : 'btn'"
          class="ml-4 !px-3 !py-2 !text-md !font-semibold"
          has-icon
          @click="toggleMap"
        >
          <BaseIcon
            :class="mapVisible ? 'mr-1' : 'mr-2.5'"
            :name="mapVisible ? 'close' : 'map'"
            aria-hidden="true"
            focusable="false"
          />
          {{ $t(mapVisible ? 'global.close' : 'search.seeOnMap') }}
        </BaseButton>
        <BaseButton
          v-else-if="!isSmallScreen"
          class="ml-4 !px-4 !py-3 text-md !font-normal"
          color="grey"
          variant="outline"
          has-icon
          @click="toggleMap"
        >
          <BaseIcon
            :name="mapVisible ? 'viewGrid' : 'map'"
            aria-hidden="true"
            class="mr-2.5"
            focusable="false"
          />
          {{ $t(mapVisible ? 'search.list' : 'search.map') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style>
.multiselect-replica .multiselect {
  @apply border-0;
  min-height: auto;
}
.multiselect-replica .multiselect-placeholder,
.multiselect-replica .multiselect-single-label {
  @apply static	px-2;
}
.multiselect-replica .multiselect-caret {
  @apply m-0;
}
</style>
